export type ErrorMessage = { errors: string[] };

export const validateRequired = (
  content: string | undefined
): ErrorMessage | {} => {
  if (!content) {
    return { errors: ["input cannot be blank"] };
  } else {
    return {};
  }
};

// TODO
// add email validation
