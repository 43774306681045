import React from "react";
import { Facebook, Linkedin } from "react-feather";
import { SocialMedia } from "../../shared/contentful/contentful.types";

const icons: Record<string, React.FunctionComponent> = {
  linkedin: Linkedin,
  facebook: Facebook,
};

const SocialIcon = ({ label, icon, url }: SocialMedia) => {
  const Icon = icons[icon];

  return (
    <a href={url} target="_blank" rel="noopener noreferrer" aria-label={label}>
      <Icon />
    </a>
  );
};
export default SocialIcon;
