import React, { ReactNode } from "react";
import { renderRichText } from "gatsby-source-contentful/rich-text";
import { BLOCKS } from "@contentful/rich-text-types";
import { Options } from "@contentful/rich-text-react-renderer";
import { SocialMedia } from "../../shared/contentful/contentful.types";
import SocialIcon from "../social-icon/SocialIcon.component";

type Props = {
  content: any;
  image: any;
  socialMedia: SocialMedia[];
};

const renderOptions: Options = {
  renderNode: {
    [BLOCKS.PARAGRAPH]: (_node: ReactNode, children: ReactNode) => (
      <p className={"contact-info-content"}>{children}</p>
    ),
  },
};

const ContactContent = ({ content, image, socialMedia }: Props) => {
  return (
    <div className="contact-info-wrapper">
      {renderRichText(content.content, renderOptions)}
      <div className="social-icons-wrapper ">
        {socialMedia &&
          socialMedia.map((s) => <SocialIcon key={s.url} {...s} />)}
      </div>
      {image && (
        <img
          src={image.media.file.url}
          alt={image.media.description}
          title={image.media.title}
        />
      )}
    </div>
  );
};
export default ContactContent;
