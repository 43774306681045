import React, { InputHTMLAttributes, useRef, useState } from "react";
import { ErrorMessage, validateRequired } from "./input.utils";

import "./input.scss";
import { Trans } from "gatsby-plugin-react-i18next";

export enum InputType {
  Text = "input",
  Textarea = "textarea",
}

export type InputProps = InputHTMLAttributes<HTMLInputElement> & {
  inputType: string;
  label: string;
  required?: boolean;
  afterValidate?: any;
};

export type InputValidationRules = {
  required?: boolean;
};

const Input = ({
  inputType,
  id,
  label,
  required,
  afterValidate,
}: InputProps) => {
  const [isFilled, setIsFilled] = useState(false);
  const [errorMessages, setErrorMessages] = useState<ErrorMessage | undefined>(
    undefined
  );
  const inputRef = useRef<HTMLInputElement | HTMLTextAreaElement>(null);

  const setError = (message: ErrorMessage) => {
    setErrorMessages(message);
    setIsFilled(false);
  };

  const setFilled = (value: string, name: string) => {
    setErrorMessages(undefined);
    setIsFilled(true);
    afterValidate(value, name);
  };

  const forceFocus = () => {
    inputRef?.current?.focus();
  };

  const validateInput = (
    e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>,
    rules: InputValidationRules = {}
  ) => {
    const { value, name } = e.target;
    const { required } = rules;

    if (required) {
      const requiredValidation = validateRequired(value);
      "errors" in requiredValidation
        ? setError(requiredValidation)
        : setFilled(value, name);
    }
    value ? setFilled(value, name) : setIsFilled(false);
  };

  const componentProps = {
    name: id,
    id,
    onBlur: (e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) =>
      validateInput(e, { required }),
  };

  return (
    <div className="input-group-wrapper">
      {required && (
        <span className="required">
          <Trans>requiredField</Trans>
        </span>
      )}
      <div
        className={`input-wrapper ${isFilled && "filled"} ${
          errorMessages && "with-error"
        } ${inputType === "textarea" ? "items-start" : "items-center"}`}
        onClick={forceFocus}
      >
        <label htmlFor={id} className={`label`}>
          {label}
        </label>
        {inputType === "textarea" ? (
          <textarea
            {...componentProps}
            ref={inputRef as HTMLTextAreaElement}
            className={"textarea"}
          />
        ) : (
          <input
            {...componentProps}
            ref={inputRef as HTMLInputElement}
            type={inputType}
            className={"input"}
          />
        )}
      </div>
      {errorMessages &&
        errorMessages.errors.map((error) => (
          <p className="error-message">{error}</p>
        ))}
    </div>
  );
};
export default Input;
