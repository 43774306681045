import React, { useContext } from "react";
import { ContactContext } from "../../pages/contact";
import StaticNavigation from "../static-navigation/StaticNavigation.component";

const ContactNavigation = () => {
  const { title, children } = useContext(ContactContext);

  return <StaticNavigation title={title}>{children}</StaticNavigation>;
};
export default ContactNavigation;
