import { graphql, PageProps } from "gatsby";
import React, { createContext, useEffect, useState } from "react";
import ContactNavigation from "../components/contact/ContactNavigation.component";

import ContactForm from "../components/contact/ContactForm.component";
import { Layout } from "../components/layout/Layout.component";
import { useResponsive } from "../hooks/useResponsive";
import {
  ContentBlock,
  ContentfulSvgImage,
  ContentfulTypenames,
  Form,
  SocialMedia,
} from "../shared/contentful/contentful.types";
import { PageContext, SEOContent } from "../shared/page.types";
import ContactContent from "../components/contact/ContactContent.component";

type ContactPageProps = {
  pageData: {
    title: string;
    form: Form;
    seo: SEOContent;
    content: (ContentBlock | ContentfulSvgImage | SocialMedia)[];
  };
};

const initialContext = {
  title: "",
  children: <></>,
};

export const ContactContext = createContext<PageContext>(initialContext);

const ContactPage = ({
  data,
  location: { href },
}: PageProps<ContactPageProps>) => {
  const [contextValue, setContextValue] = useState<PageContext>(initialContext);
  const {
    pageData: { title, form, seo, content },
  } = data;
  const { isMedium } = useResponsive();

  const contactImage = content.find(
    (c) => c.__typename === ContentfulTypenames.Image
  ) as ContentfulSvgImage | undefined;

  const contactIntro = content.find(
    (c) => c.__typename === ContentfulTypenames.ContentBlock
  ) as ContentBlock;

  const socialMedia = content.filter(
    (c) => c.__typename === ContentfulTypenames.SocialMedia
  ) as SocialMedia[];

  const seoProps = {
    ...seo,
    url: href,
  };

  useEffect(() => {
    content &&
      setContextValue({
        title: title || "Contact",
        children: (
          <ContactContent
            content={contactIntro}
            image={contactImage}
            socialMedia={socialMedia}
          />
        ),
      });
  }, [content]);

  return (
    <ContactContext.Provider value={contextValue}>
      <Layout pageNavigation={ContactNavigation} seo={seoProps}>
        <ContactForm form={form} />
        {!isMedium && (
          <ContactContent
            content={contactIntro}
            image={contactImage}
            socialMedia={socialMedia}
          />
        )}
      </Layout>
    </ContactContext.Provider>
  );
};
export default ContactPage;

export const query = graphql`
  query contactPage($language: String) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    pageData: contentfulLayoutContactPage(
      identifier: { eq: "page_contact" }
      node_locale: { eq: $language }
    ) {
      title
      form: contactForm {
        fields: contentfulfields {
          name
          label
          isRequired
          inputType
        }
        buttonLabel
      }
      content {
        ...image
        ...contentBlock
        ...sociaMedia
      }
      seo {
        ...seo
      }
    }
  }
`;
