import React from "react";
import { useState } from "react";
import { ArrowRight } from "react-feather";
import { Form } from "../../shared/contentful/contentful.types";
import Input from "../inputs/Input.component";
import "./contact.scss";

type Props = {
  form: Form;
};

type ContactFormValue = {
  name?: string;
  email?: string;
  position?: string;
  organzation?: string;
  phoneNumber?: string;
  message?: string;
};

// TODO
// add page to handle after message ?
const ContactForm = (props: Props) => {
  const { form } = props;
  const [formInfos, setFormInfos] = useState<ContactFormValue>({});
  const sendMessage = (_e: React.FormEvent<HTMLFormElement>) => {
    console.log("sending message");
  };

  const handleState = (value: string, name: string) => {
    setFormInfos({ ...formInfos, [name]: value });
  };

  return (
    <div className={"contact-wrapper"}>
      <form
        method="post"
        action={"/en/thank-you"}
        name={"contact-form"}
        data-netlify="true"
        data-netlify-honeypot="bot-field"
        onSubmit={sendMessage}
      >
        <input type="hidden" name="form-name" value={"contact-form"} />
        <input type="hidden" name="bot-field" />
        {form.fields.map((field) => (
          <Input
            key={field.name}
            inputType={field.inputType}
            name={field.name}
            id={field.name}
            label={field.label}
            required={field.isRequired}
            afterValidate={handleState}
          />
        ))}
        <div className="submit-btn-wrapper">
          <ArrowRight />
          <input
            type="submit"
            value={form.buttonLabel}
            className={"submit-btn"}
          />
        </div>
      </form>
    </div>
  );
};
export default ContactForm;
